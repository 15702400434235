.Competition {
  //position: relative;
  z-index: 7;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  margin-bottom: 40px;
  &Flex {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
  }
}
.Image {
  position: relative;
  max-height: 250px;
  max-width: 550px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.Title {
  position: relative;
  //width: 350px;
  max-width: 400px;
  //max-height: 100px;
  //overflow: hidden;
  &_Wrapper {
    display: flex;
    align-items: flex-start;
  }
  &_Big {
    font-size: 50px;
    max-height: unset;
    max-width: unset;
    width: 50%;
    overflow: unset;
    line-height: 55px;
    margin-bottom: 60px;
  }
}
@media all and (max-width: 900px) {
  .Competition {
    font-size: 30px;
    line-height: 36px;
  }
  .Image {
    max-height: 150px;
    max-width: 100%;
  }
  .Title {
    &_Big {
      font-size: 40px;
      max-height: unset;
      max-width: unset;
      width: 100%;
      overflow: unset;
      line-height: 45px;
      margin-bottom: 40px;
    }
  }
}

@media all and (max-width: 520px) {
  .Title {
    &_Big {
      font-size: 30px;
      max-height: unset;
      max-width: unset;
      width: 100%;
      overflow: unset;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
}

.HomeJobs {
  display: flex;
  background: url("../../../assets/png/works-1280_and_more.png") center top no-repeat;
  margin: 100px auto 30px auto;

  &__Paddings {
    display: flex;
    margin: auto;
    width: 100%;
  }

  .WorksBlock {
    display: flex;
    width: 60%;
    //background-size: contain;
    padding: 60px 0;
  }

  .ThemesBlock {
    width: 20%;
    margin-left: auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    &:hover {
      .StaticImg {
        display: none;
      }
    }
  }
  .Works {
    padding: 0 30px;
  }

  .Title {
    margin-left: 30px;
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 40px;
    color: #10215e;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  .Img {
    position: relative;
    width: 256px;
    height: 300px;
  }
}

@media (min-width: 85em) {
  .HomeJobs {
    &__Paddings {
      width: 85.625rem;
    }
  }
}
@media (min-width: 1400px) {
  .HomeJobs {
    .Title {
      margin-right: 100px;
    }
  }
}

@media (max-width: 1237px) {
  .HomeJobs {
    background: url("../../../assets/png/works-1280_and_more.png") 60% top no-repeat;
    .ThemesBlock {
      width: 25%;
    }
  }
}

@media (max-width: 1100px) {
  .HomeJobs {
    .WorksBlock {
      flex-flow: column nowrap;
      padding: 25px 0;
    }
    .Title {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 991px) {
  .HomeJobs {
    background: url("../../../assets/png/works-1280_and_more.png") 70% top no-repeat;
    .ThemesBlock {
      width: 30%;
    }
  }
}

@media (max-width: 768px) {
  .HomeJobs {
    background: unset;
    &__Paddings {
      flex-flow: column;
    }
    .ThemesBlock {
      width: 100%;
      .Img {
        margin: 50px auto;
      }
    }
    .WorksBlock {
      width: 100%;
      background: #ddf3fe;
      padding: 25px 0 50px 0;
    }
  }
}

.NotFound {
  height: 500px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #394c94;
  font-weight: bold;
  .big {
    font-size: 5em;
  }
}


.HomeInformationComponent {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 90px;
  padding: 35px 10px;

  &--row {
    display: grid;
    grid-template-columns: 1fr;
  }

  .title_h2 {
    margin-bottom: 30px;
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 40px;
    color: rgb(16, 33, 94);
    font-family: "Roboto Condensed";
    font-weight: 700;
    text-transform: uppercase;
  }

  &__block {
    position: relative;
    display: grid;

    .news-block {
      display: grid;

      grid-column-gap: 40px;
      font-family: "Roboto", sans-serif;

      &--two-columns {
        grid-template-columns: repeat(2, 1fr);
      }
      &--three-columns {
        grid-template-columns: 1fr 2fr;
      }

      a {
        text-decoration: none;
      }

      .day, .year {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 21px;
        color: rgb(104, 115, 181);
        font-weight: 700;
        text-transform: uppercase;
        margin-right: 3px;
      }
      .month, .time {
        margin-left: 0;
        margin-right: 8px;
        font-size: 15px;
        letter-spacing: 1px;
        line-height: 21px;
        color: #6873b5;
        font-weight: 700;
        text-transform: uppercase;
        white-space:nowrap;
      }
      .title {
        font-size: 16px;
        line-height: 22px;
        color: rgb(61, 70, 105);
        margin-top: 15px;

      }
      .image {
        width: 100%;
        height: 200px;
        //background: red;
        margin-bottom: 20px;
      }
      .news {
        margin-bottom: 30px;
      }
    }

    .grid-two-columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;
    }
    .grid-three-columns {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
    }
    .grid-two-rows {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 40px;
      grid-auto-flow: column;
    }
    .grid-one-row {
      display: grid;
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      //grid-auto-flow: column;
    }
  }
}
@media all and (max-width: 900px){
  .HomeInformationComponent {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    &__block {
      .news-block--three-columns, .news-block--two-columns {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
      }
      .grid-two-columns {
        grid-template-columns: 1fr;
      }
      .grid-two-rows {
        grid-template-columns: 1fr;
        grid-template-rows: unset;
        grid-auto-flow: unset;
      }
    }
  }
}

.Nomination {
  position: absolute;
  z-index: 12;
  img {
    width: 60px;
    position: absolute;
    top: 13px;
    left: -13px;
    &:hover {
      &+div {
        display: block;
      }
    }
  }
  &__name {
    pointer-events: none;
    display: none;
    padding: 10px 20px;
    background: #828fdb;
    color: #fff;
    position: absolute;
    bottom: -45px;
    left: 12px;
    width: 300px;
  }
}

.LogosLinks {
  margin-top: -100px;
  padding: 120px 30px 20px;
  background: #4c579a;
  display: flex;
  .LinksWrapper {
    width: 100%;
    // max-width: 1370px;
    margin: 0px auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    a {
      display: flex;
      font-size: 17px;
      //line-height: 34px;
      color: rgb(255, 255, 255);
      font-family: "Roboto Condensed";
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
      // text-align: center; // <- if comment then text will close to the icon
      max-width: 350px;
    }
    img {
      margin-right: 10px;
    }
  }
}
@media all and (min-width: 85em) {
  .LogosLinks {
    .LinksWrapper {
      width: 82rem;
    }
  }
}

@media all and (max-width: 1089px) {
  .LogosLinks {
    display: none;
  }
}

.HomeInformationCalendar, .HomeInformationComponent {
  .button {
    &-block {
      align-self: end;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 20px;
    text-decoration: none;
    margin-top: 15px;
    width: max-content;
    //padding: 8px 30px;
    border: 1px solid rgb(183, 197, 216);
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 1px;
    font-family: "Roboto Condensed";
    background-color: rgb(255, 255, 255);
    text-transform: uppercase;
    transition: .2s;
    &--blue {
      color: rgb(104, 115, 181);
      &:hover {
        color: #fff;
        background: rgb(104, 115, 181);
      }
    }
    &--green {
      color: rgb(0, 188, 214);
      &:hover {
        color: #fff;
        background: rgb(0, 188, 214);
      }
    }
  }
}

.DocType {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 17px;
  color: rgb(159, 168, 184);
  font-family: "Roboto Condensed";
  font-weight: 400;
  margin: 5px 0;
}
.UnitPageComponent__flex {
  display: flex;
  justify-content: left;
}

.HomeInformationCalendar {
  display: grid;
  min-width: 340px;
  .main-content {
    position: relative;
  }
  .date {
    display: flex;
    font-family: "Roboto Condensed";
    font-weight: 700;
    color: #00bcd6;
    text-align: center;
    &--center {
      margin-left: 30%;
    }
    &__day {
      font-size: 72px;
      line-height: 60px;
    }
    &__month, &__year {
      line-height: 28px;
      font-size: 24px;
    }
    .slash {
      padding: 0 10px;
      font-size: 72px;
      line-height: 60px;
      &--small {
        font-size: 36px;
        margin-right: -20px;
      }
    }
  }
  .stage {
    margin: 20px 0;
    font-family: "Roboto Condensed", sans-serif;
    a {
      text-decoration: none;
    }
    &__title {
      //margin-bottom: 20px;
      color: #232c5f;
      font-size: 26px;
      margin-bottom: 10px;
      line-height: 30px;
    }
    &__description {
      width: 70%;
      font-family: "Roboto", sans-serif;
      color: #3d4669;
      font-size: 16px;
      text-decoration: underline;
    }
  }
  .schedule {
    position: absolute;
    right: 0;
    top: 0;
    &--square {
      position: absolute;
      width: 75px;
      height: 55px;
      top: -5px;
      right: 0;
      background-color: rgb(0, 188, 214);
    }
    &--link {
      position: absolute;
      right: 14px;
      a {
        right: 20px;
        top: 6px;
        font-size: 16px;
        color: rgb(61, 70, 105);
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        line-height: 20px;
        text-shadow: 0 0 3px rgba(255,255,255,.6);
      }
    }
  }
}
@media all and (max-width: 900px) {
  .HomeInformationCalendar {
    .date {
      justify-content: center;
    }
    .stage__title {
      text-align: center;
    }
    .stage__description {
      text-align: center;
      margin: auto;
    }
    .schedule {
      top: -90px;
    }
  }
}

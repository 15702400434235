.flex {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  flex-grow: 1;

  &-block {
    display: flex;
  }

  &-item {
    position: relative;
    padding: 0 15px;
    &:hover .flex-menu {
      display: block;
      z-index: 13;
      max-height: 300px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
        background-color: #3d4e86;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(190, 204, 252, 0.6);
        outline: 1px solid #BECCFC;
        border-radius: 10px;
      }
    }
  }
}


.WorkBlock {
  position: relative;
  width: 100px;
  height: 60px;
  background: #5dc5fc;
  font-family: "Roboto Condensed", sans-serif;
  color: #10215e;
  &__Wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 80px;
    grid-column-gap: 80px;
  }
  &__Empty {
    position: relative;
    width: 100px;
    height: 60px;
    background: #ccedfe;
    &:before {
      position: absolute;
      content: '';
      top: 9px;
      left: 22px;
      width: 53px;
      height: 47px;
      background: url(../../assets/svg/ico-head.svg) no-repeat;
      background-size: contain;
      opacity: .3;
    }
  }
  &:before {
    position: absolute;
    content: '';
    top: -16px;
    left: -20px;
    width: 45px;
    height: 36px;
    background: url(../../assets/svg/ico-head.svg) no-repeat;
    background-size: contain;
  }
  .WorkTitle {
    position: absolute;
    right: 3px;
    top: 25px;
    font-weight: 700;
    font-size: 36px;
    line-height: 30px;
    &_Small {
      font-size: 16px;
      line-height: 18px;
    }
  }
  .WorkSubtitle {
    position: absolute;
    bottom: -25px;
    right: 9px;
    font-weight: 500;
    font-size: 20px;
  }
}
.ArrowBlock {
  display: flex;
  align-items: center;
  .Arrow {
    position: relative;
    margin: 0 6px;
    width: 35px;
    height: 35px;
    background: #ccedfe;
    border-radius: 50%;
    cursor: pointer;
    //&:hover {
    //  background: #bee9ff;
    //}
    &.active {
      background: #a0dfff;
      &:hover {
        background: #90d3f5;
      }
    }
    &__Prev {
      &:after {
        position: absolute;
        content: '';
        top: 10px;
        left: 12px;
        width: 15px;
        box-sizing: border-box;
        height: 15px;
        border-style: solid;
        border-color: white;
        border-width: 0 1px 1px 0;
        transform: rotate(135deg);
      }
    }
    &__Next {
      &:after {
        position: absolute;
        top: 10px;
        left: 7px;
        content: '';
        width: 15px;
        box-sizing: border-box;
        height: 15px;
        border-style: solid;
        border-color: white;
        border-width: 0 1px 1px 0;
        transform: rotate(-45deg);
      }
    }
  }
}
@media all and (max-width: 1100px) {
  .WorkBlock {
    &__Wrapper {
      grid-column-gap: 20px;
    }
  }
}

@media all and (max-width: 768px){
  .WorkBlock {
    &__Wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
